<template>
  <b-modal
    id="new-invoice-modal"
    size="lg"
    hide-header
    hide-footer
    @show="onShow"
    @hidden="cancelForm"
  >
    <div class="modal-header">
      <div class="modal-title">
        Emissão de Nota Fiscal de Serviço Eletrônica
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="cancelForm" />
      </span>
    </div>

    <div class="modal-content">
      <validation-observer ref="form">
        <div class="form-group">
          <validation-provider name="prestador" rules="required">
            <label class="form__label">Prestador</label>

            <multiselect
              class="with-border"
              v-model="providerSelected"
              :options="activeCompanies"
              track-by="id"
              label="razao_social"
              placeholder="Selecionar prestador"
              :showLabels="false"
              :allowEmpty="false"
              @select="selectProvider"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="option" slot-scope="props">
                <div class="props-option">
                  <div class="patient-name">
                    {{ props.option.razao_social }}
                  </div>
                  <div class="patient-props">
                    <div class="prop-option__info">
                      cnpj: {{ cnpjFormated(props.option.cnpj) }}
                    </div>
                  </div>
                </div>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <span
              class="error-message"
              v-if="validated && !nfData.prestador_razao_social"
            >
              Campo obrigatório
            </span>
          </validation-provider>
        </div>

        <hr />

        <validation-provider
          name="paciente"
          class="search-tomador__container"
          v-if="!isPatientDisabled || nfData.tomador.nome === undefined"
        >
          <label class="form__label">Pesquisar paciente</label>

          <multiselect
            ref="patient"
            v-model="patient"
            class="with-border"
            :options="patients"
            track-by="id"
            label="name"
            :showLabels="false"
            :allowEmpty="false"
            :loading="loading"
            :internal-search="false"
            @search-change="debounceGetPatients"
            @select="selectPatient"
            placeholder="Nome do paciente"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="patient-name-doctor">
                {{ props.option.name }}
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="props-option">
                <div class="patient-name">{{ props.option.name }}</div>
                <div class="patient-props">
                  <div v-if="props.option.cpf" class="patient-cpf">
                    CPF {{ cpfFormated(props.option.cpf) }}
                  </div>
                </div>
              </div>
            </template>

            <template slot="noOptions">
              <div>Digite para pesquisar seus pacientes...</div>
            </template>
            <template slot="noResult">
              <div>Não foi encontrado paciente cadastrado com este nome...</div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name }}
            </template>
          </multiselect>
        </validation-provider>

        <b-row class="my-3">
          <b-col cols="6">
            <div class="form-group">
              <label for="nome">Nome completo</label>
              <validation-provider
                name="nome"
                rules="required"
                v-slot="{ errors, touched, dirty }"
              >
                <input
                  autocomplete="off"
                  id="nome"
                  type="text"
                  :disabled="isPatientDisabled && nfData.tomador.nome"
                  v-model="nfData.tomador.nome"
                  placeholder="Nome completo"
                  class="form-control"
                  :class="{ error: errors.length > 0 && dirty }"
                />
                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !touched && !nfData.tomador.nome"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <validation-provider
                name="cpf"
                rules="required|cpf"
                v-slot="{ errors, touched, dirty }"
              >
                <label for="cnpj_cpf">CPF</label>
                <the-mask
                  :masked="false"
                  autocomplete="off"
                  id="cnpj_cpf"
                  type="text"
                  v-model="nfData.tomador.cnpj_cpf"
                  class="form-control"
                  placeholder="000.000.000-00"
                  :mask="['###.###.###-##']"
                  :class="{ error: errors.length > 0 && dirty }"
                />
                <span
                  class="error-message"
                  v-if="
                    validated && nfData.tomador.cnpj_cpf && errors.length > 0
                  "
                >
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !touched && !nfData.tomador.cnpj_cpf"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label for="email">E-mail</label>
              <validation-provider
                mode="lazy"
                rules="required|email"
                name="e-mail"
                v-slot="{ touched, errors, dirty }"
              >
                <b-form-input
                  autocomplete="off"
                  id="email"
                  type="text"
                  placeholder="email@example.com.br"
                  v-model="nfData.tomador.email"
                  :class="{ error: errors.length > 0 && dirty }"
                />

                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !touched && !nfData.tomador.email"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <AddressForm
          :patient="patient"
          @updateAddress="updateAddress"
          :invalidForm="validated"
        />
        <hr />
        <h3 class="title__l">Serviço Prestado</h3>

        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <label for="valor_servicos">Valor da nota</label>

              <validation-provider
                mode="lazy"
                name="valor da nota"
                rules="required"
                v-slot="{ touched, errors }"
              >
                <money
                  id="valor_servicos"
                  placeholder="R$ 00,00"
                  v-model.lazy="nfData.servico.valor_servicos"
                  class="form-control"
                />
                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !nfData.servico.valor_servicos && !touched"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <validation-provider
                name="aliquota"
                rules="required"
                v-slot="{ errors, touched, dirty }"
              >
                <label for="aliquota">Alíquota</label>
                <the-mask
                  autocomplete="off"
                  id="aliquota"
                  type="text"
                  disabled
                  :masked="true"
                  class="form-control"
                  placeholder="00,00%"
                  v-model="nfData.servico.aliquota"
                  :mask="['#%', '#,#%', '#,##%', '##,##%']"
                  :class="{ error: errors.length > 0 && dirty }"
                />

                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !touched && !nfData.servico.aliquota"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="data_emissao">Data de emissão</label>

              <validation-provider
                mode="lazy"
                name="data de emissão"
                rules="required"
                v-slot="{ touched, errors }"
              >
                <date-picker
                  placeholder="00/00/0000"
                  :disabled-date="isFutureDate"
                  v-model="nfData.data_emissao"
                  :clearable="false"
                  format="DD/MM/YYYY"
                  id="data_emissao"
                  :lang="langDatePicker"
                  @change="checkScheduleDate($event)"
                ></date-picker>
                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !nfData.data_emissao && !touched"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <br />

        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <label for="discriminacao">Descrição</label>

              <validation-provider
                mode="lazy"
                name="descrição"
                rules="required"
                v-slot="{ touched, errors }"
              >
                <b-form-input
                  autocomplete="off"
                  id="discriminacao"
                  type="text"
                  placeholder="Adicionar observações"
                  v-model="nfData.servico.discriminacao"
                  class="form-control"
                />
                <span class="error-message" v-if="touched && errors.length > 0">
                  {{ errors[0] }}
                </span>
                <span
                  class="error-message"
                  v-if="validated && !nfData.servico.discriminacao && !touched"
                >
                  Campo obrigatório
                </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </div>

    <div class="m-4 text-right">
      <b-button variant="outline-danger" class="mr-3" @click="cancelForm">
        Cancelar
      </b-button>

      <b-button variant="primary" @click="preSave"> Prosseguir </b-button>
    </div>

    <ConfirmNfAppointmentModal
      :scheduleDate="scheduleDate"
      @cancelScheduleIssue="cancelScheduleIssue"
    />

    <ConfirmCloseNfModal @saveAccountData="preSave" @closeModal="closeModal" />
  </b-modal>
</template>

<script>
import moment from 'moment'
import { debounce, isEqual } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import { isFutureDate } from '@/utils/dateHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import {
  cpfFormated,
  cnpjFormated,
  numberWithoutPercent
} from '@/utils/dataHelper.js'
import api from '@/modules/nfe/api'
import Swal from 'sweetalert2'

export default {
  name: 'NewInvoiceModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ConfirmNfAppointmentModal: () =>
      import('@/modules/nfe/modals/ConfirmNfAppointmentModal'),
    ConfirmCloseNfModal: () => import('./ConfirmCloseNfModal.vue'),
    AddressForm: () => import('@/components/General/AddressForm')
  },
  data() {
    return {
      patients: [],
      patient: null,
      loading: false,
      validated: false,
      scheduleDate: null,
      providerSelected: null,
      serviceProviderList: [],
      clinic: getCurrentClinic(),
      nfData: this.getDefaultNfData(),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      debounceGetPatients: debounce(this.searchPatient, 300),
    }
  },
  computed: {
    ...mapState('nfe', [
      'companies',
      'nfModalData',
      'isPatientDisabled',
      'providerModalSelected'
    ]),
    ...mapGetters('nfe', [
        'activeCompanies'
    ])
  },
  methods: {
    ...mapActions('nfe', ['getCompanies']),
    moment,
    cpfFormated,
    isFutureDate,
    cnpjFormated,
    async onShow() {
      await this.getContract();

      await this.getCompanies(this.clinic.id)
      if(this.companies.length === 0) {
        this.$toast.error('não há Cadastros para emitir NFe');
        this.$bvModal.hide('new-invoice-modal')
      }

      if (!this.nfModalData.tomador) {
        this.nfData = this.getDefaultNfData()
      } else {
        // o component que chama a modal passa o provider pela store
        this.providerSelected = {
          id: this.providerModalSelected.id,
          clinic_id: this.providerModalSelected.clinic_id,
          cnpj: this.providerModalSelected.cnpj,
          razao_social: this.providerModalSelected.razao_social
        }
        this.nfData = this.nfModalData

        if (this.nfData.tomador?.patient_id) {
          await this.getPerson(this.nfData.tomador.patient_id)
          this.nfData.tomador.endereco = this.patient.address
        } else if (this.nfData.tomador?.cnpj_cpf) {
          await this.getPersonByCpf(this.providerModalSelected.clinic_id, this.nfData.tomador.cnpj_cpf)
          this.nfData.tomador.endereco = this.patient.address
        }
      }
    },

    async getPerson(value) {
      const isLoadding = this.$loading.show()
      try {
        const { data } = await this.api.getCompletePatient(value)
        this.patient = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoadding.hide()
      }
    },
    async getPersonByCpf(clinic_id, cnpj_cpf) {
      const isLoadding = this.$loading.show()
      try {
        const { data } = await this.api.findPatientBy(clinic_id, 'patient_cpf', cnpj_cpf)
        this.patient = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoadding.hide()
      }
    },
    getDefaultNfData() {
      return {
        data_emissao: '',
        prestador_razao_social: '',
        prestador_cnpj: '',
        prestador_id: '',
        tomador: {
          cnpj_cpf: '',
          nome: '',
          email: '',
          id: '',
          endereco: {
            logradouro: null,
            numero: null,
            complemento: null,
            bairro: null,
            codigo_municipio: null,
            uf: null,
            cep: null
          }
        },
        servico: {
          aliquota: '',
          discriminacao: '',
          valor_servicos: 0
        }
      }
    },
    updateAddress(address) {
      this.nfData.tomador.endereco.logradouro = address.address
      this.nfData.tomador.endereco.numero = address.number
      this.nfData.tomador.endereco.complemento = address.complement
      this.nfData.tomador.endereco.bairro = address.neighborhood
      this.nfData.tomador.endereco.municipio = address.city
      this.nfData.tomador.endereco.uf = address.state
      this.nfData.tomador.endereco.cep = address.zipCode
    },
    selectProvider(provider) {
      this.nfData.prestador_id = provider.id
      this.nfData.prestador_razao_social = provider.razao_social
      this.nfData.prestador_cnpj = provider.cnpj
      this.nfData.servico.aliquota = provider.config.aliquota
    },
    async searchPatient(query) {
      this.loading = true
      try {
        const { data } = await this.api.searchPatients(
          getCurrentClinic().id,
          query
        )
        this.patients = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    selectPatient(patient) {
      this.nfData.tomador = {
        nome: patient.name,
        cnpj_cpf: patient.cpf,
        email: patient.email,
        endereco: {
          logradouro: patient.address?.address ?? null,
          numero: patient.address?.number ?? null,
          complemento: patient.address?.complement ?? null,
          bairro: patient.address?.neighborhood ?? null,
          codigo_municipio: null,
          municipio: patient.address?.city ?? null,
          uf: patient.address?.state ?? null,
          cep: patient.address?.zipCode ?? null
        }
      }
    },
    closeModal() {
      this.patient = {}
      this.validated = false
      this.providerSelected = null
      this.nfData = this.getDefaultNfData()
      this.$bvModal.hide('new-invoice-modal')
      this.$store.dispatch('nfe/updateIsPatientDisabled', false)
    },
    async isValid() {
      this.validated = true

      const isValid = this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.$toast.error('Por favor verificar os campos inválidos')
          return false
        } else {
          return true
        }
      })

      return isValid
    },
    async preSave() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      this.$store.dispatch(
        'nfe/updateProviderModalSelected',
        this.providerSelected
      )

      const params = {
        ...this.nfData,
        servico: {
          ...this.nfData.servico,
          aliquota:
            typeof this.nfData.servico.aliquota === 'string'
              ? numberWithoutPercent(this.nfData.servico.aliquota)
              : this.nfData.servico.aliquota,
          valor_servicos: this.nfData.servico.valor_servicos
        }
      }

      this.$store.dispatch('nfe/updateNfModalData', params)

      this.updateSelectedPatient()

      this.validated = false

      this.$bvModal.show('invoice-resume')
      this.closeModal()
    },

    async updateSelectedPatient() {
      this.patient.address = {
        address: this.nfData.tomador.endereco.logradouro,
        number: this.nfData.tomador.endereco.numero,
        complement: this.nfData.tomador.endereco.complemento,
        neighborhood: this.nfData.tomador.endereco.bairro,
        city: this.nfData.tomador.endereco.municipio,
        state: this.nfData.tomador.endereco.uf,
        zipCode: this.nfData.tomador.endereco.cep
      }
      await this.api.updatePerson(this.patient.id, this.patient)
    },
    checkScheduleDate($event) {
      const selectedDate = new Date($event)
      const today = new Date()

      if (moment(selectedDate).isAfter(today)) {
        this.scheduleDate = selectedDate
        this.$bvModal.show('confirm-nf-appointment-modal')
      }
    },
    cancelScheduleIssue() {
      this.nfData.data_emissao = null
    },
    cancelForm() {
      const formHasChanged = !isEqual(this.nfData, this.getDefaultNfData())
      if (formHasChanged) {
        this.$bvModal.show('confirm-close-modal')
      } else {
        this.closeModal()
      }
    },
    getFirstAndLastDaysOfMonth() {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);

      const firstDayIso = firstDay.toISOString();
      const lastDayIso = lastDay.toISOString();
      
      return { firstDayIso, lastDayIso };
    },
    async getContract() {
      const firstAndLastDaysOfMonth = this.getFirstAndLastDaysOfMonth();
      const { data } = await api.getDashboard(
        this.clinic.id,
        firstAndLastDaysOfMonth.firstDayIso,
        firstAndLastDaysOfMonth.lastDayIso
      );

      if(data.isExceedingNote){
        Swal.fire({
          title: 'Atenção',
          text: 'Emissão de NF ultrapassou quantidade contratada. Deseja seguir com a Emissão de NF excedente?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          allowOutsideClick: false,
          confirmButtonColor: "#305bf2",
        }).then((result) => {
          if (result.isDismissed) {
            this.closeModal();
          }
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';

#new-invoice-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #525c7a;
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .modal-content {
      border: none;
      padding: 1.4rem;
    }

    .error-message {
      padding-left: 4px;
    }

    .props-option {
      display: flex;
      justify-content: space-between;

      .prop-option__info {
        font-size: 14px;
      }

      .patient-props {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--type-placeholder);
        font-size: 14px;

        .patient-cpf {
          color: var(--blue-500);
          font-weight: 400;
        }
      }
    }

    .mx-input {
      border-radius: 8px;
      border: 1px solid var(--neutral-300);
    }
    
  }
  swal2-popup{
      font-family: "Nunito Sans", sans-serif;
  }
}

.swal2-popup{
    font-family: "Nunito Sans", sans-serif;
}
</style>
